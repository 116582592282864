<template>
  <div class="token-icon px-2 py-1">
    <span v-if="token">
       <IconCrypto :coinname="token" color="color" format="svg" />{{token}}
    </span>
    <span v-else class="no-selected">
      Choose token
    </span>
  </div>
</template>

<script>
export default {
  name: "TokenIcon",
  props: {
    token: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped lang="scss">
  .token-icon {
    background-color: $gray-100;
    border-radius: 4px;
    @extend %font-small;
    height: 100%;
    @extend .flex-row;
    .no-selected { color: $gray-600;}
  }

</style>
